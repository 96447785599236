import Swiper, { Scrollbar } from "swiper";

class MediaCards extends HTMLElement {
  constructor() {
    super();

    const md = window.matchMedia("(max-width: 767px)");
    this.mediaCard = this.querySelector(".media-card");

    let spacing_desktop = 0;
    let spacing_mobile = 0;
    let spacing_lr_desktop = 0;
    let spacing_lr_mobile = 0;
    if (this.mediaCard) {
      if (this.mediaCard.getAttribute("data_spacing_mobile") == "true") {
        spacing_mobile = 12;
      } else {
        spacing_mobile = 0;
      }
      if (this.mediaCard.getAttribute("data_spacing_desktop") == "true") {
        spacing_desktop = 12;
      } else {
        spacing_desktop = 0;
      }

      if (this.mediaCard.getAttribute("data_lr_mobile") == "true") {
        spacing_lr_mobile = 12;
      } else {
        spacing_lr_mobile = 0;
      }
      if (this.mediaCard.getAttribute("data_lr_desktop") == "true") {
        spacing_lr_desktop = 80;
      } else {
        spacing_lr_desktop = 0;
      }
    }

    if (md.matches) {
      const MediaCardSlider = new Swiper(this.mediaCard, {
        modules: [Scrollbar],
        spaceBetween: spacing_mobile,
        slidesPerView: "auto",
        slidesOffsetBefore: spacing_lr_mobile,
        slidesOffsetAfter: spacing_lr_mobile,
        mousewheel: {
          forceToAxis: true,
        },
        scrollbar: {
          el: ".swiper-scrollbar",
          draggable: true,
        },
      });
    } else {
      const MediaCardSlider = new Swiper(this.mediaCard, {
        modules: [Scrollbar],
        spaceBetween: spacing_desktop,
        slidesPerView: "auto",
        slidesOffsetBefore: spacing_lr_desktop,
        slidesOffsetAfter: spacing_lr_desktop,
        mousewheel: {
          forceToAxis: true,
        },
        scrollbar: {
          el: ".swiper-scrollbar",
          draggable: true,
        },
      });
    }
  }
}

customElements.define("media-cards", MediaCards);
